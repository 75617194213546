export default {
    data() {
        return {
            paymentStatusOptions: [
                {
                    name: 'Оплачено',
                    color: '#16CE4A',
                    value: 'Paid'
                },
                {
                    name: 'Буде оплачено',
                    color: '#FFAF00',
                    value: 'To be paid'
                },
                {
                    name: 'Не оплачено',
                    color: '#ED3636',
                    value: 'Not paid'
                }
            ],
            taskStatusOptions: [
                {
                    name: 'Виконано',
                    color: '#16CE4A',
                    value: 'Completed'
                },
                {
                    name: 'Не виконано',
                    color: '#ED3636',
                    value: 'Uncompleted'
                }
            ]
        }
    }
}
