<template>
    <MainLayout>
        <div class="notifications-page">
            <div class="container">
                <h1 class="text-h2 text-bold">Сповіщення</h1>

                <div class="notifications-list">
                    <div
                        v-for="(item, index) in notificationsStore.notificationsList"
                        :key="index"
                        class="notification-item"
                    >
                        <div class="item-type">
                            <img
                                class="type-icon"
                                :src="notificationTypes[item.data.type].icon"
                                alt=""
                            />
                        </div>
                        <div class="item-info">
                            <div class="item-title text-h4 text-bold">{{ item.title }}</div>
                            <div class="item-description text-h4">{{ item.description }}</div>
                            <div class="item-date text-h5 text-grey">{{ item.data.send_at }}</div>
                        </div>
                    </div>
                    <div
                        v-if="!notificationsStore.lastPage"
                        class="load-more-btn text-h4 text-grey text-center"
                        @click="loadNotificationsList"
                    >
                        Завантажити ще
                    </div>
                    <div
                        v-if="notificationsStore.lastPage"
                        class="all-loaded text-h4 text-grey text-center"
                    >
                        Всі сповіщення завантажені
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <LoaderComponent v-if="notificationsStore.showLoader" />
        </Transition>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import { useNotificationsStore } from '@/stores/notifications.store.js'
import LoaderComponent from '@/components/LoaderComponent.vue'
import ntParcelIcon from '@/assets/images/nt-parcel-icon.svg'
import ntPassengerIcon from '@/assets/images/nt-passenger-icon.svg'
import ntRouteIcon from '@/assets/images/nt-route-icon.svg'
import ntPointIcon from '@/assets/images/nt-point-icon.svg'
import ntTaskIcon from '@/assets/images/nt-task-icon.svg'

export default {
    name: 'NotificationsView',
    components: { LoaderComponent, MainLayout },
    setup() {
        const notificationsStore = useNotificationsStore()

        return { notificationsStore }
    },
    data() {
        return {
            notificationTypes: {
                parcel: {
                    icon: ntParcelIcon
                },
                passenger: {
                    icon: ntPassengerIcon
                },
                route: {
                    icon: ntRouteIcon
                },
                route_point: {
                    icon: ntPointIcon
                },
                route_point_task: {
                    icon: ntTaskIcon
                }
            }
        }
    },
    mounted() {
        this.loadNotificationsList()
    },
    unmounted() {
        this.notificationsStore.notificationsList = []
        this.notificationsStore.pageNum = 1
        this.notificationsStore.lastPage = false
    },
    methods: {
        loadNotificationsList() {
            this.notificationsStore.loadNotificationsList()
        }
    }
}
</script>

<style scoped lang="scss">
.notifications-page {
    padding: 1.25rem 0 0;
}

.notification-item {
    display: flex;
    padding: 1rem 0;
}

.notifications-list {
    padding-top: 1rem;
}

.item-type {
    margin-right: 0.7rem;

    .type-icon {
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
    }
}

.item-title {
    margin-bottom: 0.25rem;
}

.item-description {
    margin-bottom: 0.5rem;
}

.load-more-btn,
.all-loaded {
    margin-top: 1.5rem;
}
</style>
