<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.5 12.9C25.4611 11.5234 25.3308 10.6456 24.8895 9.897C24.2611 8.83125 23.1304 8.238 20.87 7.0536L18.7683 5.9511C16.923 4.98405 16.0004 4.5 15.0084 4.5C14.0164 4.5 13.0938 4.983 11.2485 5.9511L9.14682 7.0536C6.88646 8.238 5.75575 8.83125 5.12735 9.897C4.5 10.9617 4.5 12.2878 4.5 14.937V15.0598C4.5 17.7111 4.5 19.0372 5.12735 20.1019C5.75575 21.1677 6.88646 21.761 9.14682 22.9464L11.2485 24.0478C13.0938 25.0159 14.0164 25.5 15.0084 25.5C16.0004 25.5 16.923 25.017 18.7683 24.0489L20.87 22.9464C23.1304 21.761 24.2611 21.1687 24.8895 20.103C25.3308 19.3543 25.4611 18.4765 25.5 17.1M24.466 10.275L20.2626 12.375M20.2626 12.375L19.7372 12.6375L15.0084 15M20.2626 12.375V16.05M20.2626 12.375L10.2796 7.125M15.0084 15L5.55084 10.275M15.0084 15V24.975"
            :stroke="color"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'ParcelIcon',
    props: {
        color: {
            type: String,
            default: '#000000'
        }
    }
}
</script>

<style scoped lang="scss"></style>
