<template>
    <GDialog
        v-model="modalStore.deleteConfirmationModal.show"
        max-width="500"
        border-radius="10"
        @update:model-value="hideModal"
    >
        <div class="modal-body">
            <div class="modal-title text-h1 text-bold">{{ modalTitle }}</div>
            <div class="modal-description text-h2">{{ modalDescription }}</div>
            <div class="modal-buttons">
                <ButtonComponent
                    class="cancel-btn"
                    btn-style="grey"
                    text="Скасувати"
                    @click="hideModal"
                />
                <ButtonComponent
                    class="save-btn"
                    btn-style="gradient"
                    text="Видалити"
                    @click="submitDelete"
                />
            </div>
        </div>
    </GDialog>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import { useModalStore } from '@/stores/index.js'

export default {
    name: 'ConfirmationModal',
    components: { ButtonComponent },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        modalDescription: {
            type: String,
            default: 'Цю дію не можливо відмінити'
        }
    },
    emits: ['submitDelete'],
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    methods: {
        hideModal() {
            this.modalStore.deleteConfirmationModal.show = false
            this.modalStore.deleteConfirmationModal.itemIdForDelete = null
        },

        submitDelete() {
            this.$emit('submitDelete', this.modalStore.deleteConfirmationModal.itemIdForDelete)
            this.hideModal()
        }
    }
}
</script>

<style scoped lang="scss">
.modal-title,
.modal-description {
    text-align: center;
}
</style>
