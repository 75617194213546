<template>
    <GDialog
        v-model="modalStore.taskModal.show"
        max-width="500"
        border-radius="10"
        @update:model-value="hideModal"
    >
        <div class="entity-modal">
            <div class="container">
                <div class="modal-body">
                    <div class="modal-info">
                        <template v-if="taskData.info?.type === 'parcel'">
                            <div class="sender-info">
                                <div class="info-col">
                                    <div class="text-h5 text-grey">Відправник</div>
                                    <div class="text-h2 text-bold">
                                        {{ taskData.info?.sender?.full_name }}
                                    </div>
                                </div>
                                <a :href="`tel:${taskData.info?.sender?.phone}`" class="call-btn">
                                    <img src="@/assets/images/phone-icon.svg" alt="" />
                                </a>
                            </div>

                            <div class="info-block">
                                <div class="info-title text-h3 text-bold">
                                    Деталі посилки №{{ taskData.info?.id }}:
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Приблизна вага:</div>
                                    <div class="text-h4">{{ taskData.info?.weight }}</div>
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Габарити:</div>
                                    <div class="text-h4">{{ taskData.info?.dimensions }}</div>
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Опис:</div>
                                    <div class="text-h4">{{ taskData.info?.description }}</div>
                                </div>
                            </div>

                            <div class="info-block">
                                <div class="info-title text-h3 text-bold">Куди?</div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Населений пункт:</div>
                                    <div class="text-h4">
                                        {{ taskData.info?.delivery_data?.to?.name }}
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Адреса:</div>
                                    <div class="text-h4">
                                        {{ taskData.info?.delivery_data?.to?.location_name }}
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Індекс:</div>
                                    <div class="text-h4">
                                        {{ taskData.info?.delivery_data?.to?.zip_code }}
                                    </div>
                                </div>
                            </div>

                            <div class="recipient-info">
                                <div class="info-col">
                                    <div class="text-h5 text-grey">Отримувач</div>
                                    <div class="text-h2 text-bold">
                                        {{ taskData.info?.recipient?.full_name }}
                                    </div>
                                </div>
                                <a
                                    :href="`tel:${taskData.info?.recipient?.phone}`"
                                    class="call-btn"
                                >
                                    <img src="@/assets/images/phone-icon.svg" alt="" />
                                </a>
                            </div>
                        </template>

                        <template v-if="taskData.info?.type === 'passenger'">
                            <div class="passenger-info">
                                <div class="info-col">
                                    <div class="text-h5 text-grey">Пасажир</div>
                                    <div class="text-h2 text-bold">
                                        {{ taskData.info?.passenger?.full_name }}
                                    </div>
                                </div>
                                <a
                                    :href="`tel:${taskData.info?.passenger?.phone}`"
                                    class="call-btn"
                                >
                                    <img src="@/assets/images/phone-icon.svg" alt="" />
                                </a>
                            </div>

                            <div class="info-block">
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Посадка:</div>
                                    <div class="text-h4">
                                        {{ taskData.from?.address }}
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="text-h5 text-grey">Висадка:</div>
                                    <div class="text-h4">
                                        {{ taskData.to?.address }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="payment-status">
                            <span class="text-h5 text-grey"> Статус оплати: </span>
                            <StatusComponent
                                v-if="taskData.info?.payment"
                                :status-options="paymentStatusOptions"
                                :current-status="taskData.info?.payment"
                                @update-status="updatePaymentStatus"
                            />
                        </div>

                        <div class="task-info">
                            <div class="info-col">
                                <div class="info-title text-h5 text-grey">
                                    Статус виконання завдання:
                                </div>
                                <div class="task-location">
                                    <img
                                        :src="actionsOptions[taskData.action_type]?.icon"
                                        class="action-icon"
                                        alt=""
                                    />
                                    <span class="text-h4">
                                        {{ taskData.action_route_point?.address }}
                                    </span>
                                </div>
                            </div>
                            <StatusComponent
                                v-if="taskData.action_status"
                                :status-options="taskStatusOptions"
                                :current-status="taskData.action_status"
                                @update-status="updateTaskStatus"
                            />
                        </div>

                        <ImagesComponent
                            v-if="taskData.info?.images"
                            title-class="text-h3 text-bold"
                            form-data-key="images[]"
                            :block-data="taskData.info?.images"
                            block-title="Фото"
                            @load-image="loadImage"
                        />

                        <ConfirmationModal
                            modal-title="Видалити фото?"
                            @submit-delete="submitImageDelete"
                        />

                        <div class="comment-wrap">
                            <textarea
                                v-model="commentText"
                                placeholder="Поле для приміток"
                                class="comment-input text-h3"
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="modal-buttons shadow-sm">
                    <div class="container">
                        <ButtonComponent
                            class="cancel-btn"
                            btn-style="grey"
                            text="Скасувати"
                            @click="hideModal"
                        />
                        <ButtonComponent
                            class="save-btn"
                            btn-style="gradient"
                            text="Зберегти"
                            @click="updateComment"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <LoaderComponent v-if="showLoader" />
        </Transition>
    </GDialog>
</template>

<script>
import { useActiveRouteStore, useModalStore } from '@/stores/index.js'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import ButtonComponent from '@/components/ButtonComponent.vue'
import StatusComponent from '@/components/StatusComponent.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import ImagesComponent from '@/components/ImagesComponent.vue'
import actionMixin from '@/mixins/actionMixin.js'
import statusesMixin from '@/mixins/statusesMixin.js'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
    name: 'TaskModal',
    components: {
        ImagesComponent,
        ConfirmationModal,
        StatusComponent,
        ButtonComponent,
        LoaderComponent
    },
    mixins: [actionMixin, statusesMixin],
    props: {
        withRequest: {
            type: Boolean,
            default: true
        },
        modalData: {
            type: Object,
            default: () => {}
        }
    },
    setup() {
        const modalStore = useModalStore()
        const activeRouteStore = useActiveRouteStore()

        return { modalStore, activeRouteStore }
    },
    data() {
        return {
            taskData: {},
            commentText: '',
            showLoader: true
        }
    },
    watch: {
        'modalStore.taskModal.show': {
            handler(newVal) {
                if (newVal && this.withRequest) {
                    this.loadModalInfo()
                }

                if (newVal && !this.withRequest) {
                    this.taskData = this.modalData
                    this.commentText = this.modalData?.info?.comment
                    this.showLoader = false
                }
            },
            immediate: true
        }
    },
    methods: {
        loadModalInfo() {
            this.showLoader = true

            fetchWrapper
                .post(`/route-point-tasks/load-page/${this.modalStore.taskModal.taskId}`, {})
                .then((response) => {
                    this.taskData = response
                    this.commentText = response.info.comment
                })
                .finally(() => {
                    this.showLoader = false
                })
        },

        updatePaymentStatus(status) {
            fetchWrapper
                .patch('/route-point-entities/update-payment-status', {
                    id: this.taskData.info.id,
                    type: this.taskData.info.type,
                    status
                })
                .then((response) => {
                    this.taskData.info.payment = response
                    this.$toast.success('Статус оплати змінено')
                })
        },

        updateTaskStatus(status) {
            this.activeRouteStore
                .updateTaskStatus(status, this.taskData.action_id)
                .then((response) => {
                    this.taskData.action_status = response.data
                    this.activeRouteStore.updateStatusesInRouteData(
                        this.taskData.action_id,
                        response
                    )
                    this.$toast.success('Статус завдання змінено')
                })
        },

        updateComment() {
            if (this.commentText !== this.taskData?.info?.comment) {
                fetchWrapper
                    .patch('/route-point-entities/update-comment', {
                        id: this.taskData.info.id,
                        type: this.taskData.info.type,
                        comment: this.commentText
                    })
                    .then(() => {
                        this.hideModal()
                        this.$toast.success('Дані оновлено')
                    })
            } else {
                this.hideModal()
                this.$toast.success('Дані оновлено')
            }
        },

        async loadImage(formData) {
            formData.append('id', this.taskData.info.id)
            formData.append('type', this.taskData.info.type)

            fetchWrapper
                .post('/route-point-entities/create-images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.taskData.info.images = response
                    this.$toast.success('Фото додано')
                })
        },

        submitImageDelete(id) {
            fetchWrapper
                .delete(
                    `/route-point-entities/delete-image/${this.modalStore.deleteConfirmationModal.itemIdForDelete}`,
                    {}
                )
                .then(() => {
                    this.taskData.info.images = this.taskData.info.images.filter((item) => {
                        return item.id !== id
                    })
                    this.$toast.success('Фото видалено')
                })
        },

        hideModal() {
            this.modalStore.taskModal.show = false
            this.modalStore.taskModal.taskId = null
            this.taskData = {}
            this.commentText = ''

            if (!this.withRequest) {
                this.$router.push({ name: 'homePage' })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.sender-info,
.recipient-info,
.passenger-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light;
    padding: 1rem 0;

    .call-btn {
        display: flex;
        height: fit-content;
        margin-left: 1.25rem;
    }
}

.sender-info {
    padding-top: 0;
}

.info-block {
    padding: 1rem 0;
    border-bottom: 1px solid $grey-light;

    .info-title {
        margin-bottom: 0.7rem;
    }

    .info-row {
        display: flex;
        margin-bottom: 0.7rem;

        div:first-of-type {
            margin-right: 0.5rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.payment-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $grey-light;
}

.task-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $grey-light;

    .info-col {
        max-width: 70%;
    }

    .info-title {
        margin-bottom: 0.7rem;
    }

    .task-location {
        display: flex;
        align-items: center;
        margin-right: 1.25rem;
    }

    .action-icon {
        margin-right: 0.5rem;
    }
}

.images-block {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-light;
    padding: 1rem 0;

    .row-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .row-title {
            margin-right: 1.5rem;
        }

        .file-input {
            display: none;
        }

        label {
            height: fit-content;
        }
    }

    .row-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .photo-item {
            position: relative;
            width: calc(50% - 0.25rem);
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;
            overflow: hidden;
            padding-top: 28%;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 1.25rem;
            }

            .photo-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .delete-icon {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            z-index: 5;
        }
    }
}

.comment-wrap {
    padding: 1.25rem 0 0 0;

    .comment-input {
        padding: 0.7rem 1rem;
        width: 100%;
        height: 6rem;
        border: 1px solid $grey-light;
        border-radius: 0.7rem;
        appearance: none;
        overflow: hidden;
        resize: none;
    }
}
</style>
