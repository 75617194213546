<template>
    <button :class="[btnClasses[btnStyle]]" class="button-component text-h3" :disabled="disabled">
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        text: {
            type: String,
            required: true
        },
        btnStyle: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            btnClasses: {
                gradient: 'gradient-btn',
                grey: 'grey-btn'
            }
        }
    }
}
</script>

<style scoped lang="scss">
.button-component {
    &:disabled {
        background: $grey;
    }
}

.btn-style {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    width: 100%;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    color: $white;
    min-height: 3rem;
}

.gradient-btn {
    @extend .btn-style;
    background: linear-gradient(90deg, #f86e2d, #ffaf00, #ffc656, #f86e2d);
    background-size: 400%;

    &:hover {
        animation: gradientAnim 8s linear infinite;
    }
}

.grey-btn {
    @extend .btn-style;
    background: $grey;

    &:hover {
        box-shadow: 0 0 3rem 0 rgba($black, 0.1);
    }
}

@keyframes gradientAnim {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 400%;
    }
}
</style>
