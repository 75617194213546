import '@/assets/styles/_main.scss'
import 'gitart-vue-dialog/dist/style.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router'
import { useAuthStore } from '@/stores'
import { GDialog } from 'gitart-vue-dialog'
import Toaster from '@meforma/vue-toaster'

startApp()

async function startApp() {
    const app = createApp(App)

    app.use(createPinia())
    app.use(Toaster, {
        position: 'top'
    })
    app.component('GDialog', GDialog)

    const authStore = useAuthStore()

    await authStore.refreshToken()

    app.use(router)

    app.mount('#app')
}
