import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetch-wrapper.js'

export const useNotificationsStore = defineStore({
    id: 'notifications',
    state: () => ({
        notificationsList: [],
        pageNum: 1,
        lastPage: false,
        showLoader: true
    }),
    actions: {
        async loadNotificationsList() {
            this.showLoader = true

            await fetchWrapper
                .post('/notifications/load-list', { page: this.pageNum })
                .then((response) => {
                    this.notificationsList.push(...response)
                    if (response.length < 10) {
                        this.lastPage = true
                    }

                    this.pageNum++
                })
                .finally(() => {
                    this.showLoader = false
                })
        }
    }
})
