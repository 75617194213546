<template>
    <MainLayout>
        <div class="my-routes-page">
            <div class="container">
                <div class="route-tabs">
                    <div
                        class="tab-btn text-h4"
                        :class="{ active: currentRouteStatus === 'Future route' }"
                        @click="switchRouteTab('Future route')"
                    >
                        Майбутні поїздки
                    </div>
                    <div
                        class="tab-btn text-h4"
                        :class="{ active: currentRouteStatus === 'Finished route' }"
                        @click="switchRouteTab('Finished route')"
                    >
                        Завершені поїздки
                    </div>
                </div>
                <div class="routes-list">
                    <RouteComponent
                        v-for="(route, index) in routesList"
                        :key="index"
                        :route-info="route"
                    />
                    <div
                        v-if="!lastPage"
                        class="load-more-btn text-h4 text-grey text-center"
                        @click="loadRoutesList"
                    >
                        Завантажити ще
                    </div>
                    <div v-if="lastPage" class="text-h4 text-grey text-center">
                        Всі поїздки завантажені
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <LoaderComponent v-if="showLoader" />
        </Transition>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import RouteComponent from '@/components/RouteComponent.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
    name: 'MyRoutesView',
    components: { LoaderComponent, RouteComponent, MainLayout },
    data() {
        return {
            routesList: [],
            currentRouteStatus: 'Future route',
            pageNum: 1,
            lastPage: false,
            showLoader: true
        }
    },
    mounted() {
        this.loadRoutesList()
    },
    methods: {
        switchRouteTab(status) {
            this.routesList = []
            this.currentRouteStatus = status
            this.pageNum = 1
            this.lastPage = false
            this.loadRoutesList()
        },

        loadRoutesList() {
            this.showLoader = true

            fetchWrapper
                .post('/routes/load-list', { status: this.currentRouteStatus, page: this.pageNum })
                .then((response) => {
                    this.routesList.push(...response)
                    if (response.length < 10) {
                        this.lastPage = true
                    }

                    this.pageNum++
                })
                .finally(() => {
                    this.showLoader = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.my-routes-page {
    padding: 1.25rem 0 0;
}

.route-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 2px solid $grey-light;

    .tab-btn {
        position: relative;
        text-align: center;
        width: calc(50% - 0.5rem);
        padding-bottom: 1rem;

        &.active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: $yellow;
            }
        }
    }
}

.route-component {
    margin-bottom: 1rem;
}

.load-more-btn {
    margin-top: 1.5rem;
}
</style>
