<template>
    <div class="route-component shadow-sm">
        <div class="route-name text-h2 text-bold">
            {{ routeInfo?.name }}
        </div>
        <div class="route-info">
            <div class="route-date text-h4 text-grey">Виїзд: {{ routeInfo?.departure_date }}</div>
            <div class="route-departured text-h4 text-grey">
                К-сть зупинок: {{ routeInfo?.points?.[1].sort }}
            </div>
        </div>
        <ButtonComponent
            v-if="extended && routeInfo.status?.value === 'Future route'"
            btn-style="gradient"
            text="Почати поїздку"
            @click="startRoute"
        />
        <ButtonComponent
            v-if="extended && routeInfo.status?.value === 'Active route'"
            btn-style="gradient"
            text="Перейти до поїздки"
            @click="goToActiveRoute"
        />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import { useActiveRouteStore } from '@/stores/index.js'

export default {
    name: 'RouteComponent',
    components: { ButtonComponent },
    props: {
        routeInfo: {
            type: Object
        },
        extended: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    methods: {
        startRoute() {
            this.activeRouteStore.startRoute(this.routeInfo.id).then(() => {
                this.goToActiveRoute()
            })
        },

        goToActiveRoute() {
            this.$router.push({ name: 'routePage' })
        }
    }
}
</script>

<style scoped lang="scss">
.route-component {
    padding: 1.25rem;
    border-radius: 0.5rem;

    .route-name {
        margin-bottom: 0.5rem;
    }

    .button-component {
        margin-top: 1.25rem;
    }
}
</style>
