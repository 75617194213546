<template>
    <RouterView />
</template>

<script>
import { SafeArea } from 'capacitor-plugin-safe-area'
import { PushNotifications } from '@capacitor/push-notifications'
import { useActiveRouteStore } from '@/stores/index.js'
export default {
    name: 'App',
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    data() {
        return {
            updateMethods: {
                route: this.updateRoute,
                route_point: this.updatePoint,
                route_point_task: this.updateTask
            }
        }
    },
    mounted() {
        this.updateSafeAreaInsets()
        this.listenToSafeAreaChanges()
        this.addNotificationListeners()
    },
    beforeDestroy() {
        SafeArea.removeAllListeners()
    },
    methods: {
        addNotificationListeners() {
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                const events = JSON.parse(notification.data.event)

                events.map((item) => {
                    this.updateMethods[item.entity_type](item)
                })
            })

            PushNotifications.addListener('pushNotificationActionPerformed', () => {
                this.$router.push({ name: 'notificationsPage' })
            })
        },

        updateRoute() {
            this.activeRouteStore.loadActiveRouteData()
        },

        updatePoint(data) {
            this.activeRouteStore.loadPointData(data.entity_id)
        },

        updateTask() {},

        async updateSafeAreaInsets() {
            try {
                const { insets } = await SafeArea.getSafeAreaInsets()

                this.applySafeAreaPadding(insets)
            } catch (error) {
                console.error('Error getting safe area insets:', error)
            }
        },

        listenToSafeAreaChanges() {
            SafeArea.addListener('safeAreaChanged', ({ insets }) => {
                this.applySafeAreaPadding(insets)
            })
        },

        applySafeAreaPadding(insets) {
            const padding = `${insets.top}px ${insets.right}px ${insets.bottom}px ${insets.left}px`

            // document.documentElement.style.setProperty('padding', padding)
        }
    }
}
</script>

<style lang="scss" scoped></style>
