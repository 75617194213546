function base64DecodeUnicode(str) {
    const percentEncodedStr = atob(str)
        .split('')
        .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')

    return decodeURIComponent(percentEncodedStr)
}

export default (token) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const decodedData = JSON.parse(base64DecodeUnicode(base64))
    const userData = decodedData.user

    userData.access_token = token
    userData.exp = decodedData.exp

    return userData
}
