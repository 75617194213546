<template>
    <MainLayout>
        <TaskModal :with-request="false" :modal-data="entityData" />
    </MainLayout>
</template>

<script>
import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import MainLayout from '@/layouts/MainLayout.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import TaskModal from '@/components/modals/TaskModal.vue'
import { useModalStore } from '@/stores/index.js'

export default {
    name: 'ScannerView',
    components: { TaskModal, MainLayout },
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    data() {
        return {
            entityId: '',
            entityType: '',
            entityData: {}
        }
    },
    mounted() {
        this.checkPermission().then((status) => {
            if (!status.denied) {
                this.startScan()
            }
        })
    },
    beforeUnmount() {
        this.stopScan()
    },
    methods: {
        async checkPermission() {
            try {
                const status = await BarcodeScanner.checkPermission({ force: true })

                if (status.denied) {
                    this.$router.push({ name: 'homePage' })
                    this.$toast.error('Надайте додатку доступ до камери')
                }

                return status
            } catch (error) {
                this.$toast.error('Виникла помилка при скануванні')
                throw error
            }
        },

        async startScan() {
            const hasPermission = await this.checkPermission()

            if (hasPermission.denied) {
                return
            }

            try {
                await BarcodeScanner.hideBackground()
                document.body.classList.add('scanner-active')

                BarcodeScanner.startScan()
                    .then((result) => {
                        if (result.hasContent) {
                            const url = result.content
                            const lastSlashIndex = url.lastIndexOf('/')
                            const secondLastSlashIndex = url.lastIndexOf('/', lastSlashIndex - 1)

                            if (secondLastSlashIndex !== -1) {
                                const lastParam = url.substring(lastSlashIndex + 1)
                                const secondLastParam = url.substring(
                                    secondLastSlashIndex + 1,
                                    lastSlashIndex
                                )

                                this.entityId = lastParam
                                this.entityType = secondLastParam
                            }

                            this.loadData()
                        }

                        document.body.classList.remove('scanner-active')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } catch (error) {
                document.body.classList.remove('scanner-active')
                await BarcodeScanner.showBackground()
                console.error('Error starting scan:', error)
            }
        },

        async stopScan() {
            try {
                await BarcodeScanner.stopScan()
                document.body.classList.remove('scanner-active')
                await BarcodeScanner.showBackground()
            } catch (error) {
                console.error('Error stopping scan:', error)
            }
        },

        loadData() {
            fetchWrapper
                .post('/route-point-entities/get-first-uncompleted-task', {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                })
                .then((response) => {
                    if (response.data?.length === 0) {
                        this.$toast.error('По цьому Qr-коду задач немає')
                        this.$router.push({ name: 'homePage' })

                        return
                    }
                    this.entityData = response.data
                    this.openEntityModal()
                })
        },

        openEntityModal() {
            this.modalStore.taskModal.show = true
            this.modalStore.taskModal.taskId = this.entityData.action_id
        }
    }
}
</script>

<style scoped lang="scss"></style>
