<template>
    <div class="login-page">
        <div class="login-bg"></div>
        <div class="container">
            <div class="logo">
                <img src="@/assets/images/logo.svg" alt="" />
            </div>
            <form class="login-form shadow-sm" @submit.prevent="authStore.login(loginData)">
                <h1 class="form-title text-h1 text-bold text-center">Вхід</h1>
                <p class="form-description text-h4 text-center">Раді бачити вас знову)</p>
                <InputComponent v-model="loginData.email" type="email" placeholder="E-mail" />
                <InputComponent v-model="loginData.password" type="password" placeholder="Пароль" />
                <ButtonComponent btn-style="gradient" class="text-h2" text="Увійти" type="submit" />
            </form>

            <!--            <a href="" class="contact-link">-->
            <!--                <img src="@/assets/images/phone-icon.svg" class="contact-icon" alt="" />-->
            <!--                <span class="contact-text text-h4 text-grey">Зв’язок з менеджером</span>-->
            <!--            </a>-->
        </div>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/index.js'
import InputComponent from '@/components/form/InputComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

export default {
    name: 'LoginView',
    components: { ButtonComponent, InputComponent },
    setup() {
        const authStore = useAuthStore()

        return {
            authStore
        }
    },
    data() {
        return {
            loginData: {
                email: '',
                password: '',
                device: ''
            }
        }
    },
    mounted() {
        this.registerNotificationService()
    },
    methods: {
        registerNotificationService() {
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                PushNotifications.requestPermissions().then((result) => {
                    if (result.receive === 'granted') {
                        PushNotifications.register()
                    } else {
                        this.$toast.error('Надайте додатку доступ до сповіщень')
                    }
                })

                PushNotifications.addListener('registration', (token) => {
                    this.loginData.device = token.value
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-page {
    position: relative;
    height: 100%;
    padding: 5rem 0 2rem;

    .login-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: url('@/assets/images/login-bg.svg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover;
        border-radius: 0 0 2rem 2rem;
        z-index: -1;
    }

    .logo {
        display: flex;
        padding: 1rem 1.5rem;
        background: $white;
        border-radius: 1.25rem;
        width: fit-content;
        margin: 0 auto;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 2rem);
    max-width: 30rem;
    background: $white;
    padding: 3rem 1rem;
    border-radius: 2rem;
    transform: translate(-50%, -50%);

    .form-title {
        margin-bottom: 0.8rem;
    }

    .form-description {
        margin-bottom: 2rem;
    }
}

.contact-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;

    .contact-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.25rem;
    }
}

@media screen and (max-height: 750px) {
    .login-page {
        padding: 2rem 0;
    }
}
</style>
