<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="9.75" :stroke="color" stroke-width="1.5" />
        <path
            d="M17.4004 12.3654C17.4004 13.6772 16.322 14.7557 14.9732 14.7557C13.6243 14.7557 12.5459 13.6772 12.5459 12.3654C12.5459 11.0535 13.6243 9.9751 14.9732 9.9751C16.322 9.9751 17.4004 11.0535 17.4004 12.3654Z"
            :stroke="color"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.75 19.2001C10.5022 17.0498 12.5686 15.5056 15 15.5056C17.4313 15.5056 19.4977 17.0498 20.2499 19.2001"
            :stroke="color"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'PassengerIcon',
    props: {
        color: {
            type: String,
            default: '#000000'
        }
    }
}
</script>

<style scoped lang="scss"></style>
