import axios from 'axios'
import { useAuthStore } from '@/stores'
import { createToaster } from '@meforma/vue-toaster'

const toaster = createToaster({ position: 'top' })

axios.defaults.baseURL = import.meta.env.VITE_DRIVER_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    const token = useAuthStore()?.user?.access_token

    config.headers.Authorization = token

    return config
})

const fetchWrapper = {
    get(url, params = {}) {
        return axios
            .get(url, { params })
            .then((response) => response.data)
            .catch((error) => {
                toaster.error(error.response.data.message)
                throw error
            })
    },

    post(url, data, options = {}) {
        return axios
            .post(url, data, options)
            .then((response) => response.data)
            .catch((error) => {
                toaster.error(error.response.data.message)
                throw error
            })
    },

    put(url, data) {
        return axios
            .put(url, data)
            .then((response) => response.data)
            .catch((error) => {
                toaster.error(error.response.data.message)
                throw error
            })
    },

    patch(url, data) {
        return axios
            .patch(url, data)
            .then((response) => response.data)
            .catch((error) => {
                toaster.error(error.response.data.message)
                throw error
            })
    },

    delete(url, data) {
        return axios
            .delete(url, data)
            .then((response) => response.data)
            .catch((error) => {
                toaster.error(error.response.data.message)
                throw error
            })
    }
}

export default fetchWrapper
