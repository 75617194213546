<template>
    <div class="map-component">
        <div ref="mapContainer" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import GraphHopperRouting from 'graphhopper-js-api-client/src/GraphHopperRouting.js'
import { useActiveRouteStore } from '@/stores/index.js'
export default {
    name: 'MapComponent',
    props: {
        waypoints: {
            type: Array,
            required: true
        }
    },
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    data() {
        return {
            routingMap: null,
            routingControl: null,
            paddingAroundWaypoints: 50,
            routeLayers: []
        }
    },
    computed: {
        pointStatuses() {
            return this.waypoints.map((point) => point.status.value)
        }
    },
    watch: {
        waypoints: {
            handler() {
                this.updateRoutes()
            }
        },
        pointStatuses: {
            handler() {
                this.updateRoutes()
            }
        }
    },
    mounted() {
        this.ghRouting = new GraphHopperRouting(
            {
                key: import.meta.env.VITE_GRAPHHOPPER_KEY,
                host: import.meta.env.VITE_GRAPHHOPPER_URL
            },
            { elevation: false }
        )

        this.routingMap = this.createMap()

        const defaultBounds = new L.LatLngBounds(
            new L.LatLng(...this.waypoints[0].coordinates),
            new L.LatLng(...this.waypoints[this.waypoints.length - 1].coordinates)
        )

        this.routingMap.fitBounds(defaultBounds)
        this.routingMap.panBy({ x: 0, y: 120 })

        this.$nextTick(() => {
            this.updateRoutes()
        })
    },
    methods: {
        createMap() {
            const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            })

            const map = L.map(this.$refs.mapContainer, {
                layers: [osm],
                zoomControl: false
            })

            L.control
                .zoom({
                    position: 'bottomleft'
                })
                .addTo(map)

            return map
        },

        updateRoutes() {
            if (!this.routingMap) {
                console.warn('Карта ще не ініціалізована.')

                return
            }

            const waypoints = this.waypoints.map((point) => [
                point.coordinates[1],
                point.coordinates[0]
            ])

            const routeData = {
                waypoints: waypoints,
                markers: [],
                layer: L.geoJson(null).addTo(this.routingMap)
            }

            this.clearRoutes(routeData)

            waypoints.forEach((point, idx) => {
                this.addMarkerToPoint(point, idx, routeData)
            })

            this.buildRoute(routeData)
            this.routeLayers.push(routeData.layer)
        },

        clearRoutes() {
            this.routeLayers.forEach((layer) => {
                this.routingMap.removeLayer(layer)
            })
            this.routeLayers = []
        },

        addMarkerToPoint(point, markerIndex, routeData) {
            const marker = L.marker([point[1], point[0]], {
                icon: L.divIcon({
                    className: 'numbered-icon',
                    html: `<div class="route-marker" style="background-color: ${
                        this.waypoints[markerIndex]?.status?.value === 'Completed'
                            ? '#16ce4a'
                            : '#2F68D5'
                    };">${markerIndex + 1}</div>`,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41]
                }),
                draggable: false
            }).addTo(routeData.layer)

            routeData.markers.push(marker)
        },

        buildRoute(routeData) {
            if (routeData.waypoints.length > 1) {
                this.ghRouting
                    .doRequest({ points: routeData.waypoints })
                    .then((json) => {
                        const path = json.paths[0]

                        L.geoJson(path.points, {
                            style: {
                                color: '#2F68D5',
                                weight: 5,
                                opacity: 0.6
                            }
                        }).addTo(routeData.layer)
                    })
                    .catch((err) => {
                        console.error('Detailed error:', err)
                    })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.map-component {
    height: 100%;
    position: relative;
}
</style>

<style lang="scss">
.leaflet-bottom {
    display: none !important;
}

.numbered-icon {
    color: white;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px !important;
    font-weight: bold;
    margin-top: -10px !important;
}

.route-marker {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.75rem;
}
</style>
