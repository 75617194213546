<template>
    <nav class="navbar shadow-sm">
        <div class="container">
            <div class="nav-links">
                <router-link :to="{ name: 'accountPage' }" class="nav-link">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.2508 10.9815C20.2508 13.8625 17.8854 16.213 14.9489 16.213C12.0124 16.213 9.64697 13.8625 9.64697 10.9815C9.64697 8.10047 12.0124 5.75 14.9489 5.75C17.8854 5.75 20.2508 8.10047 20.2508 10.9815Z"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5 24.0002C6.43284 19.9045 10.3688 16.9631 14.9999 16.9631C19.631 16.9631 23.567 19.9045 24.9999 24.0002"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
                <router-link :to="{ name: 'routePage' }" class="nav-link">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.4091 22.2726H10.8939C9.82503 22.2726 8.7999 21.8536 8.04407 21.1077C7.28824 20.3618 6.86362 19.3502 6.86362 18.2953C6.86362 17.2405 7.28824 16.2289 8.04407 15.483C8.7999 14.7371 9.82503 14.3181 10.8939 14.3181H20.106C21.175 14.3181 22.2001 13.899 22.9559 13.1532C23.7117 12.4073 24.1364 11.3956 24.1364 10.3408C24.1364 9.28596 23.7117 8.27433 22.9559 7.52844C22.2001 6.78256 21.175 6.36353 20.106 6.36353L12.7727 6.36353"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M23.3375 23.3913C23.849 22.8689 24.1364 22.1605 24.1364 21.4218C24.1364 20.6832 23.849 19.9748 23.3375 19.4523C23.0844 19.1937 22.7837 18.9885 22.4528 18.8485C22.1219 18.7085 21.7673 18.6365 21.4091 18.6365C21.0509 18.6365 20.6962 18.7085 20.3654 18.8485C20.0345 18.9885 19.7338 19.1937 19.4806 19.4523C18.9692 19.9748 18.6818 20.6832 18.6818 21.4218C18.6818 22.1605 18.9692 22.8689 19.4806 23.3913L20.0732 23.9875L20.8691 24.777L20.9209 24.8239C21.2229 25.0737 21.6651 25.0578 21.9495 24.777L22.8981 23.8344L23.3375 23.3913Z"
                            stroke="black"
                            stroke-width="1.5"
                        />
                        <circle
                            cx="9.1364"
                            cy="6.36364"
                            r="1.36364"
                            stroke="black"
                            stroke-width="1.5"
                        />
                    </svg>
                </router-link>
                <router-link :to="{ name: 'homePage' }" class="nav-link">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M25 21.807V14.4243C25 13.8294 24.8774 13.2408 24.6396 12.6941C24.4017 12.1475 24.0537 11.6543 23.6167 11.2445L16.5311 4.60307C16.1181 4.21589 15.5702 4 15.0006 4C14.4309 4 13.883 4.21589 13.47 4.60307L6.38333 11.2445C5.94628 11.6543 5.59826 12.1475 5.36045 12.6941C5.12264 13.2408 5 13.8294 5 14.4243V21.807C5 22.3886 5.23413 22.9464 5.65087 23.3577C6.06762 23.769 6.63285 24 7.22222 24H22.7778C23.3671 24 23.9324 23.769 24.3491 23.3577C24.7659 22.9464 25 22.3886 25 21.807Z"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M11.6667 17.421C11.6667 16.8394 11.9008 16.2816 12.3176 15.8703C12.7343 15.4591 13.2995 15.228 13.8889 15.228H16.1111C16.7005 15.228 17.2657 15.4591 17.6825 15.8703C18.0992 16.2816 18.3334 16.8394 18.3334 17.421V24H11.6667V17.421Z"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
                <router-link :to="{ name: 'notificationsPage' }" class="nav-link">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.7504 20.0842C19.5438 19.8636 21.3058 19.4238 23 18.774C21.5652 17.1226 20.7724 14.9759 20.7756 12.7511V12.0009C20.7756 10.4094 20.1672 8.88303 19.0841 7.75763C18.0011 6.63224 16.5321 6 15.0005 6C13.4688 6 11.9999 6.63224 10.9168 7.75763C9.8338 8.88303 9.22535 10.4094 9.22535 12.0009V12.7511C9.22825 14.976 8.43511 17.1227 7 18.774C8.66805 19.4141 10.4266 19.8592 12.2506 20.0842M17.7504 20.0842C15.9235 20.3094 14.0774 20.3094 12.2506 20.0842M17.7504 20.0842C17.8891 20.5341 17.9236 21.0117 17.8511 21.4781C17.7785 21.9445 17.6011 22.3866 17.333 22.7682C17.065 23.1498 16.7141 23.4603 16.3087 23.6744C15.9034 23.8884 15.4552 24 15.0005 24C14.5458 24 14.0976 23.8884 13.6922 23.6744C13.2869 23.4603 12.9359 23.1498 12.6679 22.7682C12.3999 22.3866 12.2224 21.9445 12.1499 21.4781C12.0774 21.0117 12.1119 20.5341 12.2506 20.0842"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
                <router-link :to="{ name: 'scannerPage' }" class="nav-link">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.5 6H21.75C22.3467 6 22.919 6.23705 23.341 6.65901C23.7629 7.08097 24 7.65326 24 8.25V10.5M10.5 24H8.25C7.65326 24 7.08097 23.7629 6.65901 23.341C6.23705 22.919 6 22.3467 6 21.75V19.5M24 19.5V21.75C24 22.3467 23.7629 22.919 23.341 23.341C22.919 23.7629 22.3467 24 21.75 24H19.5M6 10.5V8.25C6 7.65326 6.23705 7.08097 6.65901 6.65901C7.08097 6.23705 7.65326 6 8.25 6H10.5"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M10 14.9954H12.4979M17.4938 14.9954V17.4931M14.9958 19.9908H17.4938M14.9958 15.0046L15.0042 14.9954M19.9917 15.0046L20 14.9954M14.9958 17.5023L15.0042 17.4931M19.9917 17.5023L20 17.4931M19.9917 20L20 19.9908M14.9958 12.5069L15.0042 12.4977M14.9958 10.0092L15.0042 10M12.4979 10.4995V11.9982C12.4979 12.1307 12.4453 12.2577 12.3516 12.3514C12.2579 12.4451 12.1308 12.4977 11.9983 12.4977H10.4996C10.3671 12.4977 10.24 12.4451 10.1463 12.3514C10.0526 12.2577 10 12.1307 10 11.9982V10.4995C10 10.3671 10.0526 10.24 10.1463 10.1463C10.24 10.0526 10.3671 10 10.4996 10H11.9983C12.1308 10 12.2579 10.0526 12.3516 10.1463C12.4453 10.24 12.4979 10.3671 12.4979 10.4995ZM12.4979 17.9927V19.4913C12.4979 19.6238 12.4453 19.7508 12.3516 19.8445C12.2579 19.9382 12.1308 19.9908 11.9983 19.9908H10.4996C10.3671 19.9908 10.24 19.9382 10.1463 19.8445C10.0526 19.7508 10 19.6238 10 19.4913V17.9927C10 17.8602 10.0526 17.7331 10.1463 17.6394C10.24 17.5458 10.3671 17.4931 10.4996 17.4931H11.9983C12.1308 17.4931 12.2579 17.5458 12.3516 17.6394C12.4453 17.7331 12.4979 17.8602 12.4979 17.9927ZM19.9917 10.4995V11.9982C19.9917 12.1307 19.939 12.2577 19.8453 12.3514C19.7517 12.4451 19.6246 12.4977 19.4921 12.4977H17.9933C17.8608 12.4977 17.7338 12.4451 17.6401 12.3514C17.5464 12.2577 17.4938 12.1307 17.4938 11.9982V10.4995C17.4938 10.3671 17.5464 10.24 17.6401 10.1463C17.7338 10.0526 17.8608 10 17.9933 10H19.4921C19.6246 10 19.7517 10.0526 19.8453 10.1463C19.939 10.24 19.9917 10.3671 19.9917 10.4995Z"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComponent'
}
</script>

<style scoped lang="scss">
.navbar {
    width: 100%;
    background: $white;
    border-radius: 0.8rem 0.8rem 0 0;

    .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7rem;
        height: 4.5rem;
    }

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        background: $grey-light;
        border-radius: 50%;
        margin: 0 0.75rem;

        &.active {
            width: 3rem;
            height: 3rem;
            background: $gradient;
            margin: 0 0.5rem;

            path,
            circle {
                stroke: $white;
            }
        }
    }
}
</style>
