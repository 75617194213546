<template>
    <div class="main-layout">
        <slot />
        <NavbarComponent />
    </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue'
import { useActiveRouteStore } from '@/stores/index.js'

export default {
    name: 'MainLayout',
    components: { NavbarComponent },
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    }
}
</script>

<style scoped lang="scss">
.main-layout {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-bottom: 6rem;

    .navbar {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 500;
    }
}
</style>
