<template>
    <div class="user-image">
        <div class="img-preview img-cover">
            <img v-if="authStore.user.avatar" :src="authStore.user.avatar" alt="" />
            <img v-else src="@/assets/images/default-user-img.svg" alt="" />
        </div>
        <div class="load-img">
            <label for="avatar-input">
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @change="selectFile"
                >
                    <circle cx="15" cy="15" r="15" fill="#FFAF00" />
                    <path
                        d="M15 17.334C16.3807 17.334 17.5 16.2147 17.5 14.834C17.5 13.4533 16.3807 12.334 15 12.334C13.6193 12.334 12.5 13.4533 12.5 14.834C12.5 16.2147 13.6193 17.334 15 17.334Z"
                        stroke="white"
                        stroke-width="1.5"
                    />
                    <path
                        d="M13.1483 21.5H16.8516C19.4525 21.5 20.7533 21.5 21.6875 20.8875C22.0905 20.6234 22.4377 20.2824 22.7091 19.8842C23.3333 18.9675 23.3333 17.69 23.3333 15.1367C23.3333 12.5825 23.3333 11.3059 22.7091 10.3892C22.4377 9.99096 22.0905 9.65004 21.6875 9.38587C21.0875 8.99171 20.3358 8.85087 19.185 8.80087C18.6358 8.80087 18.1633 8.39254 18.0558 7.86337C17.9736 7.47576 17.7602 7.1284 17.4515 6.88C17.1428 6.63159 16.7578 6.49738 16.3616 6.50004H13.6383C12.815 6.50004 12.1058 7.07087 11.9441 7.86337C11.8366 8.39254 11.3641 8.80087 10.815 8.80087C9.66496 8.85087 8.91329 8.99254 8.31246 9.38587C7.90964 9.65009 7.56277 9.99102 7.29163 10.3892C6.66663 11.3059 6.66663 12.5825 6.66663 15.1367C6.66663 17.69 6.66663 18.9667 7.29079 19.8842C7.56079 20.2809 7.90746 20.6217 8.31246 20.8875C9.24663 21.5 10.5475 21.5 13.1483 21.5Z"
                        stroke="white"
                        stroke-width="1.5"
                    />
                    <path
                        d="M20.8333 12.334H20"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                </svg>
            </label>

            <input
                id="avatar-input"
                ref="uploadInput"
                class="avatar-input"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/gif"
                @change="selectFile"
            />
        </div>

        <transition name="fade">
            <div v-if="isShowModal" class="crop-modal-wrap" @click.stop="isShowModal = false">
                <div class="crop-modal" @click.stop>
                    <VuePictureCropper
                        :options="{
                            viewMode: 1,
                            dragMode: 'crop',
                            aspectRatio: 1 / 1
                        }"
                        :preset-mode="{
                            mode: 'round',
                            width: 500,
                            height: 500
                        }"
                        :img="pic"
                    />

                    <div class="buttons-row">
                        <ButtonComponent
                            class="cancel-btn"
                            btn-style="grey"
                            text="Скасувати"
                            @click="isShowModal = false"
                        />
                        <ButtonComponent
                            class="save-btn"
                            btn-style="gradient"
                            text="Зберегти"
                            @click="getResult"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import { useAuthStore } from '@/stores'
import ButtonComponent from '@/components/ButtonComponent.vue'
export default {
    name: 'UserImageComponent',
    components: { ButtonComponent, VuePictureCropper },
    emits: ['update-avatar'],
    setup() {
        const authStore = useAuthStore()

        return { authStore }
    },
    data() {
        return {
            pic: '',
            result: {
                dataUrl: '',
                blobUrl: ''
            },
            isShowModal: false,
            uploadInput: ''
        }
    },
    methods: {
        selectFile(e) {
            this.pic = ''
            this.result.dataURL = ''
            this.result.blobURL = ''

            const { files } = e.target

            if (!files || !files.length) return

            const file = files[0]
            const reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = () => {
                this.pic = String(reader.result)
                this.isShowModal = true

                if (!this.$refs.uploadInput) return
                this.$refs.uploadInput.value = ''
            }
        },

        async getResult() {
            if (!cropper) return
            const base64 = cropper.getDataURL()
            const blob = await cropper.getBlob()

            if (!blob) return

            const file = await cropper.getFile({
                fileName: 'avatar'
            })

            this.result.dataURL = base64
            this.result.blobURL = URL.createObjectURL(blob)
            this.isShowModal = false
            this.authStore.user.avatar = base64
            this.$emit('update-avatar', file)
        }
    }
}
</script>

<style scoped lang="scss">
.user-image {
    position: relative;
    width: fit-content;
    margin: 0 auto 1.25rem;

    .load-img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 2rem;
        height: 2rem;
        z-index: 2;

        label {
            cursor: pointer;
        }

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    .img-preview {
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 50%;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    }

    .avatar-input {
        display: none;
    }
}

.crop-modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1500;
    padding: 0 1rem;
}

.crop-modal {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    background: $white;
    border-radius: 0.5rem;
    max-height: 100%;

    .buttons-row {
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;

        .save-btn,
        .cancel-btn {
            width: calc(50% - 0.5rem);
        }
    }
}

.fade-enter-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from {
    opacity: 0;
}

@media screen and (max-width: 575px) {
    .crop-modal {
        width: 100%;
        padding: 1rem;
    }
}
</style>

<style lang="scss">
.vue--picture-cropper__wrap {
    border-radius: 0.8rem;
    overflow: hidden;
}
</style>
