import { defineStore } from 'pinia'

export const useModalStore = defineStore({
    id: 'modal',
    state: () => ({
        deleteConfirmationModal: {
            show: false,
            itemIdToDelete: null
        },
        taskModal: {
            show: false,
            taskId: null
        },
        addParcelModal: {
            show: false
        },
        addPassengerModal: {
            show: false
        }
    }),
    actions: {}
})
