<template>
    <svg
        class="collapse-toggler"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ opened: opened }"
    >
        <circle
            cx="11"
            cy="11"
            r="10"
            stroke="#ffaf00"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M7 9L11 13" stroke="#ffaf00" stroke-width="1.5" stroke-linecap="round" />
        <path d="M15 9L11 13" stroke="#ffaf00" stroke-width="1.5" stroke-linecap="round" />
    </svg>
</template>

<script>
export default {
    name: 'CollapseToggler',
    props: {
        opened: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.collapse-toggler {
    min-width: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    transition: all ease-in-out 0.3s;

    &.opened {
        transform: rotate(-180deg);

        circle {
            fill: $yellow;
        }

        path {
            stroke: $white;
        }
    }
}
</style>
