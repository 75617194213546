<template>
    <div class="active-route-task" @click.stop.prevent="openEntityModal">
        <div class="task-icon">
            <ParcelIcon v-if="taskData.type === 'parcel'" :color="taskData.action_status.color" />
            <PassengerIcon
                v-if="taskData.type === 'passenger'"
                :color="taskData.action_status.color"
            />
        </div>
        <div class="task-info">
            <div class="info-name text-h4">
                {{ taskData.type === 'passenger' ? taskData.name : taskData.sender.full_name }}
            </div>
            <div class="info-location text-h5 text-grey">
                {{ taskData.from_city }} - {{ taskData.to_city }}
            </div>
        </div>
        <div class="task-action">
            <img :src="actionsOptions[taskData.action_type].icon" class="action-icon" alt="" />
        </div>
    </div>
</template>

<script>
import ParcelIcon from '@/components/icons/ParcelIcon.vue'
import PassengerIcon from '@/components/icons/PassengerIcon.vue'
import { useModalStore } from '@/stores/index.js'
import actionMixin from '@/mixins/actionMixin.js'
export default {
    name: 'ActiveRouteItem',
    components: { PassengerIcon, ParcelIcon },
    mixins: [actionMixin],
    props: {
        taskData: {
            type: Object,
            required: true
        }
    },
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    methods: {
        openEntityModal() {
            this.modalStore.taskModal.show = true
            this.modalStore.taskModal.taskId = this.taskData.action_id
        }
    }
}
</script>

<style scoped lang="scss">
.active-route-task {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    .task-icon {
        margin-right: 0.5rem;
    }

    .task-action {
        margin-left: auto;
    }
}
</style>
